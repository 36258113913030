<template>
   <div class="col-12 px-0 mb-1">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-lg text-center text-lg-start">
                  <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ produto.descricaoProduto == null ? 'Sem nome' : produto.descricaoProduto }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                     <span class="ms-3"><i class="far fa-tag font-9 me-1"></i>{{ produto.infAdProd == null ? '-' : produto.infAdProd }}</span>
                  </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1">Código:</strong>
                     <span> {{ produto.codigoEanProduto == null ? 'Sem código' : produto.codigoEanProduto }}</span>
                  </p>
               </div>
               <div class="wpx-200 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-13">
                  <span class="d-block lh-sm">
                     <small class="subtitle me-1">
                        <i class="far fa-box font-9 me-1" :class="statusNota == 'Manifestada' ? 'color-theme' : parseFloat(produto.quantidadePedido) < parseFloat(produto.qtd) ? 'text-red' : 'text-green'"></i> Nota:
                     </small>
                     <span>{{ parseFloat(produto.qtd).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 4}) }}</span>
                     <small class="font-11" v-if="statusNota != 'Manifestada' && produto.vinculado"> {{ produto.unidadeMedida == null || String(produto.unidadeMedida).trim().length == 0 ? 'UN' : produto.unidadeMedida }}</small>
                     <small class="font-11" v-else> {{ produto.unidadeProduto == null || String(produto.unidadeProduto).trim().length == 0 ? 'UN' : produto.unidadeProduto }}</small>
                  </span>
                  <span class="d-block lh-sm">
                     <small class="subtitle me-1">
                        <i class="far fa-clipboard font-9 me-1" :class="statusNota == 'Manifestada' ? 'color-theme' : parseFloat(produto.quantidadePedido) < parseFloat(produto.qtd) ? 'text-red' : 'text-green'"></i> Pedido(s):
                     </small>
                     <span>{{ parseFloat(produto.quantidadePedido).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 4}) }}</span>
                     <small class="font-11"> {{ produto.unidadeMedida == null || String(produto.unidadeMedida).trim().length == 0 ? 'UN' : produto.unidadeMedida }}</small>
                  </span>
                  <span class="d-block text-red font-10 mt-1" v-if="statusNota != 'Manifestada' && parseFloat(produto.quantidadePedido) < parseFloat(produto.qtd)">NÃO LIBERADO</span>
                  <span class="d-block text-green font-10 mt-1" v-else-if="statusNota != 'Manifestada'">LIBERADO</span>
               </div>
               <div class="wpx-250 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-13">
                  <span class="d-block lh-sm">
                     <small class="subtitle me-1">
                        <i class="far fa-money-bill font-9 me-1" :class="statusNota == 'Manifestada' ? 'color-theme' : parseFloat(produto.unitario) > parseFloat(produto.maiorCustoPedido) ? 'text-red' : 'text-green'"></i> Nota:
                     </small>
                     <span><small>R$ </small>{{ parseFloat(produto.unitario).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 4}) }}</span>
                  </span>
                  <span class="d-block lh-sm">
                     <small class="subtitle me-1">
                        <i class="far fa-clipboard font-9 me-1" :class="statusNota == 'Manifestada' ? 'color-theme' : parseFloat(produto.unitario) > parseFloat(produto.maiorCustoPedido) ? 'text-red' : 'text-green'"></i> Pedido(s):
                     </small>
                     <span><small>R$ </small>{{ parseFloat(produto.menorCustoPedido).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 4}) }}</span>
                     <span v-if="produto.menorCustoPedido != produto.maiorCustoPedido">
                        <small> ~ </small><small>R$ </small>{{ parseFloat(produto.maiorCustoPedido).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 4}) }}
                     </span>
                  </span>
                  <span class="d-block text-red font-10 mt-1" v-if="statusNota != 'Manifestada' && parseFloat(produto.unitario) > parseFloat(produto.maiorCustoPedido)">NÃO LIBERADO</span>
                  <span class="d-block text-green font-10 mt-1" v-else-if="statusNota != 'Manifestada'">LIBERADO</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'ProdutoPedido',
   props: ['produto', 'index', 'statusNota']
}

</script>