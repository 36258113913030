<template>
   <div class="row align-items-start">
      <div class="nav flex-sm-column nav-pills mx-auto px-sm-0 mb-1 mb-sm-0 justify-content-center" id="vinculo-tab" role="tablist" aria-orientation="vertical">
			<button class="nav-link mb-1 active" id="nota-tab" data-bs-toggle="pill" data-bs-target="#nota" type="button" role="tab" aria-controls="nota" aria-selected="true">
				<span class="d-flex align-items-center">
					<i class="fal fa-file mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Nota</span>
				</span>
			</button>
			<button class="nav-link mb-1" @click="nota.lockedProd = false" id="boletos-tab" data-bs-toggle="pill" data-bs-target="#boletos" type="button" role="tab" aria-controls="boletos" aria-selected="false">
				<span class="d-flex align-items-center">
					<i class="fal fa-barcode mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Boletos</span>
				</span>
			</button>
			<button class="nav-link mb-1" @click="nota.lockedActions = false" id="produtos-tab" data-bs-toggle="pill" data-bs-target="#produtos" type="button" role="tab" aria-controls="produtos" aria-selected="false" :disabled="nota.lockedProd">
				<span class="d-flex align-items-center">
					<i class="fal fa-carrot mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Produtos</span>
				</span>
			</button>
			<button class="nav-link mb-1" @click="nota.lockedActions = false" id="pedidos-tab" data-bs-toggle="pill" data-bs-target="#pedidos" type="button" role="tab" aria-controls="pedidos" aria-selected="false" :disabled="nota.lockedProd" v-if="dadosUsuario.usuarioModulos.includes('Compras')">
				<span class="d-flex align-items-center">
					<i class="fal fa-clipboard mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Pedidos</span>
				</span>
			</button>
         <button class="nav-link mb-1 d-none d-sm-block" type="button" @click="baixarNF">
            <span class="d-flex align-items-center">
               <i class="fal fa-share mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Exportar</span>
            </span>
         </button>
			<button class="nav-link mb-1 border-green" type="button" @click="manifestarNota('210200')" :disabled="nota.lockedActions"
				v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Manifestar notas') && nota.status == 'Manifestada' && dataLimiteVinculo > new Date()">
				<span class="d-flex align-items-center" :class="nota.lockedActions ? '' : 'text-green'">
					<i class="fal fa-check mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Aceitar nota</span>
				</span>
			</button>
			<button class="nav-link mb-1 border-red" type="button" @click="manifestarNota('210240')" :disabled="nota.lockedActions"
				v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Manifestar notas') && nota.status == 'Manifestada' && dataLimiteVinculo > new Date()">
				<span class="d-flex align-items-center" :class="nota.lockedActions ? '' : 'text-red'">
					<i class="fal fa-times mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Recusar nota</span>
				</span>
			</button>
		</div>

		<div class="tab-content px-sm-0" id="vinculo-tabContent">
			<div class="tab-pane fade show active" id="nota" role="tabpanel" aria-labelledby="nota-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row align-items-center">
									<div class="col">
										<h6 class="mb-0 font-15 text-capitalize"><i class="far fa-file color-theme font-13 me-2"></i>Nota</h6>
									</div>
									<div class="w-max-content">
										<a href="javascript:;" @click="mudarTab('#boletos-tab')" class="badge badge-default bg-theme ms-1">
											<i class="far fa-long-arrow-right me-1 d-none d-sm-inline"></i> Avançar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<accordionNota :nota="nota" />
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="boletos" role="tabpanel" aria-labelledby="boletos-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row align-items-center">
									<div class="col">
										<h6 class="mb-0 font-15 text-capitalize"><i class="far fa-barcode color-theme font-13 me-2"></i>Boletos</h6>
									</div>
									<div class="w-max-content">
										<a href="javascript:;" @click="mudarTab('#nota-tab')" class="badge badge-default bg-theme me-1">
											<i class="far fa-long-arrow-left me-1 d-none d-sm-inline"></i> Voltar
										</a>
										<a href="javascript:;" @click="mudarTab('#produtos-tab')" class="badge badge-default bg-theme ms-1">
											<i class="far fa-long-arrow-right me-1 d-none d-sm-inline"></i> Avançar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="row">
							<boleto v-for="(boleto, index) in nota.boletos" :key="index" :boleto="boleto" :index="index" />
							<div class="col-12 my-5 text-center" v-if="nota.boletos.length == 0">
								<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum boleto encontrado</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="produtos" role="tabpanel" aria-labelledby="produtos-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row align-items-center justify-content-between justify-content-md-start">
									<div class="col-md mb-3 mt-1 my-md-0">
										<h6 class="mb-0 font-15 text-capitalize"><i class="far fa-carrot color-theme font-13 me-2"></i>Produtos</h6>
									</div>
									<div class="w-max-content" v-if="nota.status == 'Manifestada' && nota.entradaEstoque == 'Manifestação nota'">
										<div class="row align-items-center justify-content-end">
											<div class="w-max-content pe-2"><i class="fal fa-box font-12"></i><span class="ms-1 d-none d-sm-inline"> Estoque</span></div>
											<div class="wpx-185">
												<v-select name="Desktop" class="w-100 select-sm" :options="nota.estoques" v-model="nota.idEstoque" :reduce="e => e.id" :clearable="false" :searchable="false"
													label="nome" placeholder="-" @input="trocarEstoque(nota.idEstoque)">
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control select-sm" v-model="nota.idEstoque" @change="trocarEstoque(nota.idEstoque)">
													<option v-for="(option, index) in nota.estoques" :key="index" :value="option.id">{{ option.nome }}</option>
												</select>
											</div>
										</div>
									</div>
									<div class="w-max-content">
										<a href="javascript:;" @click="mudarTab('#boletos-tab')" class="badge badge-default bg-theme me-1">
											<i class="far fa-long-arrow-left me-1 d-none d-sm-inline"></i> Voltar
										</a>
										<a href="javascript:;" @click="mudarTab('#pedidos-tab')" class="badge badge-default bg-theme ms-1" v-if="dadosUsuario.usuarioModulos.includes('Compras')">
											<i class="far fa-long-arrow-right me-1 d-none d-sm-inline"></i> Avançar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
                  <div class="row">
                     <itemNota v-for="(produto, index) in nota.lstItensNotaEletronica" :key="index" :produto="produto" :index="index" :showEdit="nota.status == 'Manifestada'" 
								:entradaEstoque="nota.entradaEstoque" @atualizar="buscarNota" @vincular="abrirModal($event)" />

                     <div class="col-12 my-5 text-center" v-if="nota.lstItensNotaEletronica.length == 0">
								<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
							</div>
                  </div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="pedidos" role="tabpanel" aria-labelledby="pedidos-tab" v-if="dadosUsuario.usuarioModulos.includes('Compras')">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row align-items-center">
									<div class="col">
										<h6 class="mb-0 font-15 text-capitalize"><i class="far fa-clipboard color-theme font-13 me-2"></i>Pedidos</h6>
									</div>
									<div class="w-max-content">
										<a href="javascript:;" @click="mudarTab('#produtos-tab')" class="badge badge-default bg-theme me-1">
											<i class="far fa-long-arrow-left me-1 d-none d-sm-inline"></i> Voltar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row">
									<div class="col">
										<label><i class="far fa-check-double color-theme font-10 me-1"></i> Selecionados</label>
										<v-select multiple v-model="pedidos.selecionados" :options="pedidos.opcoes" label="nome" :reduce="p => p.id" placeholder="Selecione pedidos" class="select-multiple" @input="compararPedidos" :disabled="pedidos.salvos.map(p => p.status).includes('Finalizado')">
											<template #option="option">
												<div class="row align-items-center">
													<div class="col-12"><h1 class="font-13 mb-1 limitador-2">{{ option.nome }}</h1></div>
													<div class="col">
														<p class="font-10 mb-0 limitador-1 opacity-90">
															<i class="far fa-calendar font-9 me-1"></i>
															<span> {{ option.data.dayOfMonth.toString().padStart(2, '0') +'/'+ option.data.monthValue.toString().padStart(2, '0') +'/'+ option.data.year }}</span>
															<span> {{ option.data.hour.toString().padStart(2, '0') +':'+ option.data.minute.toString().padStart(2, '0') }}</span>
														</p>
													</div>
													<div class="col-6 d-none d-xxl-block">
														<p class="font-10 mb-0 limitador-1 opacity-90">
															<i class="far fa-truck font-9 me-1"></i><span>{{ option.cpfCnpjFornecedor == null ? 'Sem fornecedor' : option.cpfCnpjFornecedor }}</span>
														</p>
													</div>
												</div>
											</template>
											<template v-slot:no-options>
												<span class="d-block lh-sm my-1">Nenhum pedido encontrado</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Pedidos" para adicionar</small>
											</template>
										</v-select>
									</div>
									<div class="w-max-content align-self-end" v-if="!pedidos.salvos.map(p => p.status).includes('Finalizado')">
										<button class="btn btn-primary px-sm-5" @click="finalizarPedidos"><i class="far fa-check font-12 me-1"></i> Finalizar pedidos</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="row">
                     <produtoPedido v-for="(produto, index) in pedidos.produtos" :key="index" :produto="produto" :index="index" :statusNota="nota.status" />

                     <div class="col-12 my-5 text-center" v-if="pedidos.produtos.length == 0">
								<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
							</div>
                  </div>
					</div>
				</div>
			</div>
		</div>

		<!-- modalNota -->
      <div class="modal fade" id="modalNota" tabindex="-1" aria-labelledby="modalNotaLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalNotaLabel">Produto - {{ modal.descricaoProduto }} (Cód. {{ modal.codigoEanProduto == null ? 'Sem código' : '"'+ modal.codigoEanProduto +'"' }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<pesquisaProduto ref="pesquisaProduto" :showAdd="false" @setRetorno="setRetornoPesquisaProduto($event)" />

						<div class="col-12" v-if="pesquisa.resultado.length > 0">
							<div class="card mb-0">
								<div class="card-body p-12">
									<div class="row">
										<div class="w-max-content mb-2 align-self-center">
											<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.resultado.length }} resultado(s)</div>
										</div>
										<div class="col mb-2 align-self-center text-end d-none d-sm-block">
											<div class="limitador-1 mb-1">
												<i class="far fa-file color-theme font-11 me-1"></i><strong class="subtitle font-12 me-1 d-none d-lg-inline"> Categoria:</strong>
												<span class="font-13"> {{ pesquisa.categoria == null || String(pesquisa.categoria).trim().length == 0 ? '-' : pesquisa.categoria }}</span>
												<span class="font-13"> ({{ pesquisa.subcategoria == null || String(pesquisa.subcategoria).trim().length == 0 ? '-' : pesquisa.subcategoria }})</span>
												<small class="mx-2">/</small>
												<i class="far fa-bookmark color-theme font-11 me-1"></i><strong class="subtitle font-12 me-1 d-none d-lg-inline"> Marca:</strong>
												<span class="font-13"> {{ pesquisa.marca == null || String(pesquisa.marca).trim().length == 0 ? '-' : pesquisa.marca }}</span>
												<small class="mx-2">/</small>
												<i class="far fa-apple-alt color-theme font-11 me-1"></i><strong class="subtitle font-12 me-1 d-none d-lg-inline"> Busca:</strong>
												<span class="font-13"> {{ pesquisa.nome == null || String(pesquisa.nome).trim().length == 0 ? '-' : pesquisa.nome }}</span>
											</div>
										</div>

										<produto v-for="(produto, index) in pesquisa.resultado" :key="index" :produto="produto" :index="index" :showSelect="true" @select="vincularProduto($event)" />
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import produto from '@/components/modelos/Produto3.vue'
import boleto from '@/components/fiscal/notas/Boleto.vue'
import itemNota from '@/components/fiscal/notas/Produto.vue'
import accordionNota from '@/components/modelos/AccordionNota.vue'
import pesquisaProduto from '@/components/modelos/PesquisaProdutos.vue'
import produtoPedido from '@/components/fiscal/notas/ProdutoPedido.vue'

export default {
   name: 'Nota',
	data: function () {
		return {
			dataLimiteVinculo: new Date(),
			pesquisa: {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'resultado': []},
			nota: {
				'id': null, 'chave': null, 'dataNota': {}, 'tipoEmissao': null, 'tipoOperacao': null, 'indConsumidor': null, 'indFinalidade': null, 'pis': null, 'cofins': null, 'valor': null, 
				'desconto': null, 'ipi': null, 'baseIcmsTrib': null, 'valorIcmsTrib': null, 'baseIcmsSt': null, 'valorIcmsSt': null, 'valorFrete': null, 'valorSeguro': null, 'cpfCnpjEmitente': null, 
				'nomeEmitente': null, 'razaoSocialEmitente': null, 'enderecoEmitente': null, 'numeroEnderecoEmitente': null, 'setorEmitente': null, 'cepEmitente': null, 'telefoneEmitente': null, 
				'inscricaoEstadualEmitente': null, 'estadoEmitente': null, 'xMunicipioEmitente': null, 'complementoEnderecoEmitente': null, 'regimeTributarioEmitente': null, 'entradaEstoque': null,
				'cpfCnpjDestinatario': null, 'nomeDestinatario': null, 'razaoSocialDestinatario': null, 'enderecoDestinatario': null, 'numeroEnderecoDestinatario': null, 'setorDestinatario': null, 
				'cepDestinatario': null, 'estadoDestinatario': null, 'telefoneDestinatario': null, 'inscricaoEstadualDestinatario': null, 'xMunicipioDestinatario': null, 'estoques': [],
				'complementoEnderecoDestinatario': null, 'emailDestinatario': null, 'status': null, 'lstItensNotaEletronica': [], 'pagamentos': [], 'boletos': [], 'naturezaOperacao': null, 
				'chaveReferenciada': null, 'dataFinalizado': null, 'dataRecebimento': null, 'numeroLoja': null, 'idEstoque': null, 'idLoja': null, 'lockedProd': true, 'lockedActions': true
			},
			modal: {
				'id': null, 'qtd': null, 'unitario': null, 'total': null, 'bcIcms': null, 'aliqIcms': null, 'valorIcms': null, 'bcIcmsSt': null, 'aliqIcmsSt': null, 'valorIcmsSt': null, 
				'bcIpi': null, 'aliqIpi': null, 'valorIpi': null, 'bcPis': null, 'aliqPis': null, 'valorPis': null, 'bcCofins': null, 'aliqCofins': null, 'valorCofins': null, 'valorDesconto': null, 
				'valorAdicional': null, 'aliqIcmsCredito': null, 'valorIcmsCredito': null, 'valorIBPT': null, 'ncmProduto': null, 'codigoProduto': null, 'codigoEanProduto': null, 'estoques': [],
				'descricaoProduto': null, 'cestProduto': null, 'cstPisCofins': null, 'cstIcms': null, 'unidadeProduto': null, 'cfop': null, 'origem': null, 'codBeneficio': null, 'infAdProd': null, 
				'cstIPI': null, 'valorFrete': null, 'pRedBC': null, 'vBCSTRet': null, 'pST': null, 'vICMSSubstituto': null, 'vICMSSTRet': null, 'vinculado': false, 'precos': [], 'valorOpr': null
			},
			pedidos: {'opcoes': [], 'selecionados': [], 'produtos': [], 'salvos': []}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
         this.buscarNota()
      }
	},
	components: {
		boleto, itemNota, accordionNota, pesquisaProduto, produto, produtoPedido
	},
	methods: {
		abrirModal : function (produto) {
			Object.assign(this.$data.pesquisa, this.$options.data.apply(this).pesquisa)
			
			this.modal = {
				'id': produto.id, 'qtd': produto.qtd, 'unitario': produto.unitario, 'total': produto.total, 'bcIcms': produto.bcIcms, 'aliqIcms': produto.aliqIcms, 'valorIcms': produto.valorIcms,
				'bcIcmsSt': produto.bcIcmsSt, 'aliqIcmsSt': produto.aliqIcmsSt, 'valorIcmsSt': produto.valorIcmsSt, 'bcIpi': produto.bcIpi, 'aliqIpi': produto.aliqIpi, 'valorIpi': produto.valorIpi,
				'bcPis': produto.bcPis, 'aliqPis': produto.aliqPis, 'valorPis': produto.valorPis, 'bcCofins': produto.bcCofins, 'aliqCofins': produto.aliqCofins, 'valorCofins': produto.valorCofins,
				'valorDesconto': produto.valorDesconto, 'valorAdicional': produto.valorAdicional, 'aliqIcmsCredito': produto.aliqIcmsCredito, 'valorIcmsCredito': produto.valorIcmsCredito,
				'valorIBPT': produto.valorIBPT, 'ncmProduto': produto.ncmProduto, 'codigoProduto': produto.codigoProduto, 'codigoEanProduto': produto.codigoEanProduto,
				'descricaoProduto': produto.descricaoProduto, 'cestProduto': produto.cestProduto, 'cstPisCofins': produto.cstPisCofins, 'cstIcms': produto.cstIcms, 'estoques': produto.estoques,
				'unidadeProduto': produto.unidadeProduto, 'cfop': produto.cfop, 'origem': produto.origem, 'codBeneficio': produto.codBeneficio, 'infAdProd': produto.infAdProd,
				'cstIPI': produto.cstIPI, 'valorFrete': produto.valorFrete, 'pRedBC': produto.pRedBC, 'vBCSTRet': produto.vBCSTRet, 'pST': produto.pST, 'vICMSSubstituto': produto.vICMSSubstituto,
				'vICMSSTRet': produto.vICMSSTRet, 'vinculado': produto.vinculado, 'precos': produto.precos, 'valorOpr': produto.valorOpr
			}

         $('#modalNota').modal('show')
		},
		setRetornoPesquisaProduto : function (retorno) {
			this.pesquisa = this.$options.data.call(this).pesquisa;
			setTimeout(() => this.pesquisa = retorno, 50);
		},
		trocarEstoque : function (estoque) {
			this.nota.lstItensNotaEletronica.forEach(p => p.idEstoque = estoque);
		},
		mudarTab : function (tab) {
			$(tab).click()
		},
		baixarNF : function () {
			this.$store.dispatch('baixarNF', this.nota.chave)
		},
		buscarNota : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'fiscal/getNota',
				params: {
					chave: this.$route.query.chaveNota
				}
			}).then(response => {
            let oldData = JSON.parse(JSON.stringify(this.tabs[this.$route.params.idTab].data.nota))
            let newData = response.data;
				newData.idEstoque = (oldData.idEstoque != null ? oldData.idEstoque : newData.idEstoque)
				newData.lockedProd = (oldData.lockedProd != true ? oldData.lockedProd : newData.status == 'Manifestada')
				newData.lockedActions = (oldData.lockedActions != true ? oldData.lockedActions : newData.status == 'Manifestada')
            
            oldData.lstItensNotaEletronica.forEach(oldItem => {
               newData.lstItensNotaEletronica.forEach(newItem => {
                  if (oldItem.id == newItem.id) {
                     newItem.qtd = oldItem.qtd;
                     newItem.unitario = oldItem.unitario;
                     newItem.idEstoque = oldItem.idEstoque;
                  }
               });
            });
            
            Object.assign(this.$data.nota, newData)

				this.dataLimiteVinculo = new Date(new Date(dataNota).setDate(new Date(dataNota).getDate() + 90))
				let dataNota = newData.dataNota.monthValue +'/'+ newData.dataNota.dayOfMonth +'/'+ newData.dataNota.year +' '+ newData.dataNota.hour +':'+ newData.dataNota.minute;
				
				this.pedidos.opcoes = newData.pedidos
				this.pedidos.salvos = newData.pedidos
				this.pedidos.selecionados = (newData.pedidos.length == 0 ? JSON.parse(JSON.stringify(this.tabs[this.$route.params.idTab].data.pedidos.selecionados)) : newData.pedidos.map(p => p.id))

				if (newData.pedidos.length == 0) this.buscarPedidos()
				this.compararPedidos()

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarPedidos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pedidos.opcoes, this.$options.data.apply(this).pedidos.opcoes)

			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/searchPedidos',
				headers: {'Content-Type': 'application/json'},
				data: {'loja': this.nota.idLoja, 'status': 'Pendente', 'nome': this.nota.cpfCnpjEmitente}

			}).then(response => {
				this.pedidos.opcoes = response.data.resultado

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		compararPedidos : function () {
			if (this.pedidos.selecionados.length == 0) return;

			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pedidos.produtos, this.$options.data.apply(this).pedidos.produtos)

			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/compararPedidos',
				headers: {'Content-Type': 'application/json'},
				data: {'chaveNota': this.nota.chave, 'pedidos': this.pedidos.selecionados}

			}).then(response => {
				this.pedidos.produtos = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		vincularProduto : function (produto) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/vincularProduto',
				headers: {'Content-Type': 'application/json'},
				data: {"idItemNota": this.modal.id, "idEan": produto.id}
				
			}).then(() => {
				this.buscarNota()
				$('#modalNota').modal('hide')

				this.$toast.fire({
					icon: 'success',
					title: 'Produto vinculado!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		manifestarNota : function (codigo) {
			Swal.fire({
            icon: 'warning',
            title: (codigo == '210200' ? 'Aceitar' : codigo == '210240' ? 'Recusar' : 'Manifestar') +' NF-e?',
            text: 'Essa ação é irreversível, caso necessário, adicione observações para registro abaixo',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
				input: 'textarea',
				inputPlaceholder: 'Observações',
            inputValidator: () => {
               if (this.nota.lstItensNotaEletronica.filter(p => !/^[0-9.]+$/.test(p.qtd) || !/^[0-9.]+$/.test(p.unitario) || 
						(Math.abs(parseFloat(p.total) - (parseFloat(p.qtd) * parseFloat(p.unitario))) > 0.5)).length > 0) {
                  return 'Valor total dos produtos difere do calculado.'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'fiscal/manifestarNota',
						headers: {'Content-Type': 'application/json'},
						data: {"chave": this.nota.id, "observacao": result.value, "codigo": codigo, 'produtos': this.nota.lstItensNotaEletronica}
						
					}).then(() => {
						this.buscarNota()
		
						this.$toast.fire({
							icon: 'success',
							title: 'Nota manifestada!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		},
		finalizarPedidos : function () {
			Swal.fire({
            icon: 'warning',
            title: 'Finalizar pedido(s) selecionado(s)?',
            text: 'Essa ação é irreversível.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'compras/finalizarPedidos',
						headers: {'Content-Type': 'application/json'},
						data: {'chaveNota': this.nota.chave, 'pedidos': this.pedidos.selecionados}
						
					}).then(() => {
						this.buscarNota()

						this.$toast.fire({
							icon: 'success',
							title: 'Pedido(s) finalizado(s)!'
						});
					}).catch((error) => {
						this.$store.dispatch('alternarTelaCarregamento', false)

						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					});
				}
			})
		}
	},
	mounted() {
		this.$store.dispatch('getCategorias')
		this.$store.dispatch('getMarcas')
      this.buscarNota()
	}
}

</script>

<style scoped>

#vinculo-tab .nav-link i {
   font-size: 18px;
   width: 21px;
   text-align: center;
}

#vinculo-tab .nav-link {
   line-height: 1;
   padding: 14px 20px;
   text-align: start;
   font-weight: 500;
   text-transform: uppercase;
   font-size: 12px;
	width: auto;
	flex: 1 0 0%;
   transition: none !important;
}

#vinculo-tab .nav-link * {
   transition: none !important;
}

#vinculo-tab,
#vinculo-tabContent {
   width: 100%;
}

@media (min-width: 576px) {
   #vinculo-tabContent {
      min-height: 210px;
      width: calc(100% - 85px)
   }
   
   #vinculo-tab {
      width: 85px;
   }
}

@media (min-width: 1200px) {
   #vinculo-tabContent {
      min-height: 210px;
      width: calc(100% - 210px)
   }

   #vinculo-tab {
      width: 210px;
   }
}

</style>